@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap);
body {
	margin: 0;
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-slide.slick-center .centerCard {
	transform: scale(1.18);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	color: inherit;
}

#map_2 {
	/* border-radius: 8px; */

	/* -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ml15 {
	font-weight: 800;
	font-size: 3.8em;
	text-transform: uppercase;
	letter-spacing: 0.5em;
	text-align: center;
}

.ml15 .word {
	display: inline-block;
	margin-right: 1rem;
	line-height: 1em;
	text-align: center;
}

.wrapper {
	position: fixed;
	top: 0%;
	width: 100vw;
	height: 100vh;
	background-color: white;
	border: none;
	z-index: 1000;
	-webkit-user-select: none;
}
.wrapper .box-wrap {
	width: 150px;
	height: 150px;
	/* margin: calc((100% - 70%) / 2) calc((100% - 70%) / 2); */
	top: 50%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	transform: rotate(-45deg);
}
.wrapper .box-wrap .box {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: rgba(135, 0, 0, 0.6);
	background: linear-gradient(to right, rgba(1, 131, 232, 1), rgba(89, 182, 255, 1));
	background-position: 0% 50%;
	background-size: 1000% 1000%;
	visibility: hidden;
}
.wrapper .box-wrap .box.one {
	-webkit-animation: moveGradient 15s infinite, oneMove 3.5s infinite;
	        animation: moveGradient 15s infinite, oneMove 3.5s infinite;
}
.wrapper .box-wrap .box.two {
	-webkit-animation: moveGradient 15s infinite, twoMove 3.5s 0.15s infinite;
	        animation: moveGradient 15s infinite, twoMove 3.5s 0.15s infinite;
}
.wrapper .box-wrap .box.three {
	-webkit-animation: moveGradient 15s infinite, threeMove 3.5s 0.3s infinite;
	        animation: moveGradient 15s infinite, threeMove 3.5s 0.3s infinite;
}
.wrapper .box-wrap .box.four {
	-webkit-animation: moveGradient 15s infinite, fourMove 3.5s 0.575s infinite;
	        animation: moveGradient 15s infinite, fourMove 3.5s 0.575s infinite;
}
.wrapper .box-wrap .box.five {
	-webkit-animation: moveGradient 15s infinite, fiveMove 3.5s 0.725s infinite;
	        animation: moveGradient 15s infinite, fiveMove 3.5s 0.725s infinite;
}
.wrapper .box-wrap .box.six {
	-webkit-animation: moveGradient 15s infinite, sixMove 3.5s 0.875s infinite;
	        animation: moveGradient 15s infinite, sixMove 3.5s 0.875s infinite;
}
@-webkit-keyframes moveGradient {
	to {
		background-position: 100% 50%;
	}
}
@keyframes moveGradient {
	to {
		background-position: 100% 50%;
	}
}
@-webkit-keyframes oneMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}
@keyframes oneMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}
@-webkit-keyframes twoMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}
@keyframes twoMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}
@-webkit-keyframes threeMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}
@keyframes threeMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(0% 70% 70% 0 round 5%);
		        clip-path: inset(0% 70% 70% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(0% 35% 70% round 5%);
		        clip-path: inset(0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(35% 70% 35% 0 round 5%);
		        clip-path: inset(35% 70% 35% 0 round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}
@-webkit-keyframes fourMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}
@keyframes fourMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}
@-webkit-keyframes fiveMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}
@keyframes fiveMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}
@-webkit-keyframes sixMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}
@keyframes sixMove {
	0% {
		visibility: visible;
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	14.2857% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	28.5714% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	42.8571% {
		-webkit-clip-path: inset(70% 0 0 70% round 5%);
		        clip-path: inset(70% 0 0 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	57.1428% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	71.4285% {
		-webkit-clip-path: inset(35% 0% 35% 70% round 5%);
		        clip-path: inset(35% 0% 35% 70% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	85.7142% {
		-webkit-clip-path: inset(35% round 5%);
		        clip-path: inset(35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	100% {
		-webkit-clip-path: inset(70% 35% 0% 35% round 5%);
		        clip-path: inset(70% 35% 0% 35% round 5%);
		-webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
		        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
}

