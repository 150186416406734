@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
body {
	margin: 0;
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-slide.slick-center .centerCard {
	transform: scale(1.18);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	color: inherit;
}

#map_2 {
	/* border-radius: 8px; */

	/* -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ml15 {
	font-weight: 800;
	font-size: 3.8em;
	text-transform: uppercase;
	letter-spacing: 0.5em;
	text-align: center;
}

.ml15 .word {
	display: inline-block;
	margin-right: 1rem;
	line-height: 1em;
	text-align: center;
}
